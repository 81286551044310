@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.navBar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100vw;
  max-width: 100%;
  height: 7vh;
  max-height: 75px;
  background-color: #0b142a;
  opacity: 0.95;
  z-index: 3;
}

.navText {
  color: white;
  margin: auto;
}
.navText p {
  padding-left: 43px;
  margin: 5px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  color: rgb(209, 66, 66);
}

.burger {
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
  margin: 0 20px 0 0;
  cursor: pointer;
}

.dropDown {
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 93vh;
  margin-top: 7vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #0b142a;
  opacity: 0.95;
  color: white;
  font-size: 20px;
  font-weight: 200;
  z-index: 1;
}

.dropDown p {
  margin-left: 30px;
  margin-top: 50px;
  cursor: pointer;
  transition: color 200ms ease-in-out;
}

.dropDown p:hover {
  color: rgb(170, 83, 67);
}

.link {
  text-decoration: none;
  color: inherit;
}

.transition-enter {
  opacity: 0.1;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.mapContainer {
  position: relative;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 90vw;
  background-size: cover;
  /* overflow: hidden; */
  margin-bottom: 7vh;
  margin-top: auto;
}

.mapContainer img {
  height: auto;
  max-height: 80vh;
  width: auto;
  max-width: 100%;
}

.locationPin {
  position: absolute;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 400ms ease-in-out;
}

/* .locationPin:hover {
  transform: scale(1.1);
} */

.locationPin img {
  height: auto;
  width: 20px;
}

.locationPin p {
  color: white;
  font-size: 16px;
  margin: 0 5px;
  font-weight: 200;
  letter-spacing: 0.5px;
  transition: letter-spacing 200ms ease-in-out;
  /*max-width: 90px;
  */
  -webkit-transition: letter-spacing 300ms ease-in-out;
  -moz-transition: letter-spacing 200ms ease-in-out;
  -ms-transition: letter-spacing 200ms ease-in-out;
  -o-transition: letter-spacing 200ms ease-in-out;
  -webkit-transition: letter-spacing 200ms ease-in-out;
}

.locationPin p:hover {
  letter-spacing: 1px;
}

/* peak district  --   peak district  --   peak district  --   peak district  --    */

.pinPeakD {
  top: 67%;
  right: 35%;
  transform: translate(100%, -100%);
}

/* london --  london --  london --  london --  london --  london --  london --  london --   */

.pinLondon {
  top: 82%;
  left: 87%;
  /* filter: grayscale(1); */
  transform: translate(-100%, -100%);
}

.circleLondon {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: transparent;
  border: 3px solid rgba(156, 156, 156, 0.589);
}

/* .pinLondon img {
  height: auto;
  width: 12px;
} */

.pinLondon p {
  font-size: 13px;
}

/* snowdonia -- snowdonia -- snowdonia -- snowdonia -- snowdonia -- snowdonia -- snowdonia --  */

.pinSnowdonia {
  top: 68%;
  left: 57%;
  transform: translate(-100%, -100%);
}

.pinWitterings {
  top: 89%;
  left: 83%;
  transform: translate(-100%, -100%);
}

* {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
}

.textStrong {
  font-size: 30px;
  font-weight: 600;
  width: 80%;
  margin-bottom: 15px;
  color: white;
}

.textStandard {
  margin-bottom: 15px;
  width: 250px;
  font-weight: 300;
  color: white;
}

.infoText {
  font-family: "Amatic SC", cursive;
  color: white;
  font-size: 120%;
  width: 150px;
  margin-top: 25px;
}

* {
  font-family: "Montserrat", sans-serif;
}

.card {
  position: relative;
  max-height: 800px;
  height: 97vh;
  width: 95vw;
  max-width: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-color: rgba(14, 54, 54, 0.61);
  z-index: 5;
}

.exitCard {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-size: cover;
  opacity: 0.7;
}

.cardTitle {
  margin: 150px auto 10px auto;
  font-weight: 400;
  font-size: 35px;
  color: white;
  letter-spacing: 3px;
}

.cardTripDetails {
  width: 90%;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.cardTripDetails div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.tripComment {
  width: 200px;
  text-align: center;
  font-size: 15px;
  color: white;
  margin-top: 10px;
}

.detailPageBtn,
.detailPageBtn visited {
  height: 40px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 15px;
  min-width: 140px;
  border-radius: 30px;
  background-color: white;
  opacity: 0.75;
  color: red;
  margin: auto 0 50px 0;
  border: none;
  font-size: 17px;
  font-weight: 600;
  transition: all 100ms ease-in-out;
}

.detailPageBtn:hover {
  opacity: 0.85;
}

.home {
  background-image: linear-gradient(#0b142a, #112647);
  height: 100vh;
  overflow: hidden;
}

.textWrapper {
  position: absolute;
  top: 10vh;
  left: 10%;
}

.mapWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

.cardCont {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.peakdistrict {
  position: absolute;
  padding: 5px;
  margin: 20px;
  z-index: 1;
}

.costCard {
  position: absolute;
  width: 90%;
  max-width: 300px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #0b142ade;
  border-radius: 15px;
  padding: 15px;
}

.costCard p,
.costCard h2 {
  margin: 5px 0;
  color: white;
}

.closeCross {
  position: absolute;
  right: 0;
  top: 0;
  height: 20px;
  width: 20px;
  margin: 7px;
  cursor: pointer;
}

.mapboxCont {
  min-height: 300px;
  min-width: 300px;
  border: 1px solid black;
  z-index: -2;
}

.basenav-container {
  position: fixed;
  height: 40px;
  width: 100%;
  max-width: 100vw;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  background-color: #11192e;
  font-weight: bold;
}

.basenav-item {
  color: initial;
  color: white;
  cursor: pointer;
  outline: none;
}

.active {
  color: #cd4545;
  cursor: pointer;
}

* {
  font-family: "Montserrat", sans-serif;
}

/* hero -- hero -- hero -- hero -- hero -- hero -- hero --  */

.hero {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroTitle {
  width: 100%;
  text-align: center;
  font-size: 33px;
  font-weight: 500;
  letter-spacing: 5px;
  margin: auto auto 20px auto;
}

.tripDetails {
  width: 90%;
  max-width: 800px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: row;
  font-weight: 400;
  font-size: 15px;
  font-weight: 300;
}

.tripDetails div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  margin-bottom: 8px;
}

.tripDescription {
  width: 70%;
  max-width: 350px;
  text-align: center;
  margin: 20px auto auto auto;
  font-weight: 200;
}

.tripDetailBold {
  font-weight: 600;
  height: 35px;
  display: flex;
  align-items: center;
}
.tripDetailBold div {
  margin-top: 10px;
}

.iconCont {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  max-width: 125px;
  height: content;
  width: 100%;
  margin: 0;
  margin: initial;
}
.iconCont div {
  height: 35px;
  width: 35px;
  background-size: cover;
  margin: 0;
  margin: initial;
}

/* -- cost breakdown -- cost breakdown -- cost breakdown -- cost breakdown -- cost breakdown  */

.costBreakdownIcon {
  height: 30px;
  width: 30px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.costBreakdownCont {
  position: absolute;
  width: 100vw;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* text section -- text section -- text section --  */

.textSection {
  height: 170px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: black !important;
}

.textSection p {
  padding-bottom: 20px;
  border-bottom: 1px solid black;
}

/* tiles --- tiles --- tiles --- tiles --- tiles --- tiles ---   */

.tilesCont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.tile {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
  background-position: center;
  color: white;
  padding: 30px;
  background-size: cover;
  background-repeat: no-repeat;
}

.tileTitle {
  font-size: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tileTitleIcon {
  height: 45px;
  width: 45px;
  background-size: cover;
  margin-left: 10px;
}

.tileInfo {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 400;
}

.tileBoldText {
  font-weight: 600;
  font-size: 20px;
  margin: 5px 0 7px 0;
}

.tileButton {
  height: 40px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 15px;
  min-width: 150px;
  margin-top: auto;
  border: none;
  background-color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  opacity: 0.75;
  border-radius: 100px;
  cursor: pointer;
}

/* details graphic --- details graphic --- details graphic --- details graphic --- */

.tripGraphic {
  display: flex;
  flex-direction: row;
  height: 820px;
  width: 100vw;
}

.timing {
  display: flex;
  justify-content: right;
  width: 40vw;
  background-color: #0b142a;
  padding: 20px;
}

.timing img {
  height: 100%;
  width: 80px;
  margin-left: auto;
}

.doing {
  display: flex;
  justify-content: left;
  width: 60%;
  overflow: hidden;
  background-color: rgb(248, 248, 248);
  padding: 20px;
}

.doing img {
  height: 100%;
  width: 150px;
}

.aboutPage {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.title {
  font-size: 70px;
  font-weight: 200;
  width: 100vw;
  text-align: center;
}

.aboutPage img {
  width: 90%;
  max-width: 500px;
  height: auto;
}

.aboutPage p {
  width: 85%;
  max-width: 500px;
  font-size: 20px;
  min-width: 250px;
  text-align: center;
  margin-bottom: 60px;
}

.aboutIconContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 50px;
}

.aboutIconContainer img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  margin: 0 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

