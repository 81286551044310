.aboutPage {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.title {
  font-size: 70px;
  font-weight: 200;
  width: 100vw;
  text-align: center;
}

.aboutPage img {
  width: 90%;
  max-width: 500px;
  height: auto;
}

.aboutPage p {
  width: 85%;
  max-width: 500px;
  font-size: 20px;
  min-width: 250px;
  text-align: center;
  margin-bottom: 60px;
}

.aboutIconContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin-bottom: 50px;
}

.aboutIconContainer img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  margin: 0 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}
