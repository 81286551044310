.basenav-container {
  position: fixed;
  height: 40px;
  width: 100%;
  max-width: 100vw;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  background-color: #11192e;
  font-weight: bold;
}

.basenav-item {
  color: initial;
  color: white;
  cursor: pointer;
  outline: none;
}

.active {
  color: #cd4545;
  cursor: pointer;
}
