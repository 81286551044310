.costCard {
  position: absolute;
  width: 90%;
  max-width: 300px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #0b142ade;
  border-radius: 15px;
  padding: 15px;
}

.costCard p,
.costCard h2 {
  margin: 5px 0;
  color: white;
}

.closeCross {
  position: absolute;
  right: 0;
  top: 0;
  height: 20px;
  width: 20px;
  margin: 7px;
  cursor: pointer;
}
